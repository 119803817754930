@import '../../styles/variables.scss';

.actionBg {
  display: block;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 0.01);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  visibility: hidden;
  transition: 0.5s;
  transition-delay: 0.3s;
  z-index: 60;

  &.open {
    background-color: rgba($color: #000000, $alpha: 0.6);
    visibility: visible;
    transition-delay: 0s;
  }
}

.actionSheet {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 1em 1em 0 0;
  min-height: 10em;
  padding: 1.25em;
  z-index: 70;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  transition: all 0.5s 0s;
  transform: translateY(100%);

  &.open {
    transform: translateY(0%);
    transition-delay: 0.3s;
  }
}

.topLineContainer {
  margin-top: -1em;
  padding: 0.75em;
  padding-top: 0.5em;

  & > .topLine {
    height: 2px;
    width: 2.5em;
    border-radius: 1px;
    background-color: #bebebe;
  }
}
