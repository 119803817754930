@import '../../styles/variables';

.container {
  width: 100%;
  height: 100%;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
}

.spinnerLoader {
  /* Border */
  border-width: 0.1875em;
  border-style: solid;
  border-radius: 50%;

  /* Size */
  width: 1.5em;
  height: 1.5em;

  /* Animation */
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
