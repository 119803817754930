@import '../../styles/variables.scss';

.checkbox {
  /* Display */
  display: flex;
  justify-content: space-between;
  gap: 1em;

  /* Size */
  padding: 1em;

  transition: all 0.3s;

  &.reverse {
    flex-direction: row-reverse;
  }

  &.bordered {
    border: 1px solid $color_border;
    border-radius: 1em;

    &.checked {
      &.color_primary {
        border-color: $color_primary;
      }
      &.color_success {
        border-color: $color_success;
      }
    }
  }

  &.align_start {
    align-items: flex-start;
  }

  &.align_center {
    align-items: center;
  }

  &.align_end {
    align-items: flex-end;
  }

  .texts {
    display: flex;
    flex-direction: column;
    gap: 0.25em;
    flex: 9;

    .label {
      /* Font */
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 500;
      line-height: 24px;

      /* Size */
      font-size: 1em;

      /* Color */
      color: #000;
    }

    .description {
      /* Font */
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 500;
      line-height: 19px;

      /* Size */
      font-size: 0.875em;

      /* Color */
      color: #959595;
    }
  }
}

.box {
  /* Display */
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;

  /* Color */
  background-color: transparent;
  border-color: $color_border;

  transition: all 0.3s;

  /* Size */
  &.size_small {
    width: 1.25em;
    height: 1.25em;
  }

  &.size_medium {
    width: 1.5em;
    height: 1.5em;
  }

  &.size_large {
    width: 1.75em;
    height: 1.75em;
  }

  /* Type */
  &.type_square {
    border-width: 1px;
    border-style: solid;
    border-radius: 0.5em;
  }

  &.type_radio {
    border-width: 1px;
    border-style: solid;
    border-radius: 1000px;
  }

  &.type_circle {
    border-width: 1px;
    border-style: solid;
    border-radius: 1000px;
  }

  /* Checked */
  &.checked {
    &.type_radio {
      &.color_primary {
        background-color: $color_primary;
        border-color: $color_primary;
      }
      &.color_success {
        background-color: $color_success;
        border-color: $color_success;
      }
    }

    &.type_square {
      &.color_primary {
        background-color: $color_primary;
        border-color: $color_primary;
      }
      &.color_success {
        background-color: $color_success;
        border-color: $color_success;
      }
    }

    &.type_circle {
      &.color_primary {
        border-color: $color_primary;
      }
      &.color_success {
        border-color: $color_success;
      }
    }
  }
}
