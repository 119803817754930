@import '../../styles/variables';

.segmented {
  width: fit-content;
  height: 3em;
  border-radius: 1em;

  background-color: $color_border;

  font-size: 1rem;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;

  display: flex;

  padding: 0.5em;
  box-sizing: border-box;

  position: relative;

  &.block {
    width: 100%;
  }

  .segmented__option {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .segmented__option__label {
      font-size: 0.75em;

      text-align: center;
      color: #00000066;

      z-index: 1;

      transition: color 0.2s ease-in-out;

      user-select: none;

      &.selected {
        color: $color_text;
      }
    }
  }

  .segmented__indicator {
    position: absolute;
    height: calc(100% - 1em);

    background-color: $color_background__light;

    border-radius: 0.5em;
    z-index: 0;

    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);

    transition: translate 0.2s ease-in-out;
  }
}
