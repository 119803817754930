.button_group {
  font-size: 16px;
  display: flex;
  gap: 0.5em;
  width: 100%;
}

.button_group__vertical {
  flex-direction: column;
}
