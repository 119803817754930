.inputContainer {
  /* Display */
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1em;

  /*  Font   */
  font-family: "Manrope";

  /* Size */
  width: 100%;
  height: 3.5em;
  padding: 0.5em 1em 1em 1em;

  /* Border */
  border-width: 1px;
  border-style: solid;
  border-color: #bcbcbc;
  border-radius: 1em;

  z-index: 0;

  .labelAndInput {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 0.25em;
    width: 100%;
  }

  &.focus {
    border-color: #000000;
  }

  &.status__error {
    /* Border */
    border-color: #f2002c;

    /* Color */
    color: #f2002c;
  }

  &.status__success {
    /* Border */
    border-color: #4a865b;

    /* Color */
    color: #4a865b;
  }

  &.position__bottom {
    /* Border */
    border-bottom-left-radius: 1em;
    border-bottom-right-radius: 1em;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &.position__mid {
    /* Border */
    border-radius: 0;
  }

  &.position__top {
    /* Border */
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
  }

  .label {
    cursor: text;
    z-index: 1;
    position: relative;
    user-select: none;

    /*  Font */
    font-weight: 500;
    line-height: 1em;

    /*  Size */
    font-size: 16px;

    /* Color */
    opacity: 0.6;

    transition: all 0.3s linear;
    transform: translateY(60%);

    &.minimize {
      font-size: 0.625em;
      line-height: 13px;
      transform: translateY(0%);
    }
  }

  .inputAndPrefix {
    display: flex;
    align-items: center;
    gap: 0.5em;

    width: 100%;

    .input {
      /* Size */
      width: calc(100% - 2em);
      line-height: 1.25em;
      padding: 0;
      font-size: 1em;

      /* Border */
      outline: none;
      border-width: 0;

      /* Font */
      font-family: "Manrope";
      font-weight: 500;
    }
  }
}

.messageContainer {
  /* Display */
  display: flex;
  align-items: center;
  gap: 0.25em;

  /* Margin */
  margin-top: 0.25em;

  /* Color */
  color: #f2002c;
}

.errorMessage {
  /* Font */
  font-family: "Manrope";
  font-weight: 500;
  font-size: 0.75em;
}
