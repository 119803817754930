.tag {
  /* Size */
  width: fit-content;
  padding: 0.75em 1em;
  font-size: 12px;

  /* Fonts */
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  line-height: 12px;

  /* Border */
  border-radius: 1em;

  position: relative;

  display: flex;
  align-items: center;
  gap: 0.5em;
  backdrop-filter: blur(100px);
}

.tag.block {
  width: 100%;
}

.background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: auto;
  height: auto;

  /* Border */
  border-radius: 1em;

  filter: brightness(1.5);
}

.icon {
  font-size: 1.5em;
  margin-top: -0.75em;
  margin-bottom: -0.75em;
  margin-left: -0.25em;
}
