@import '../../styles/variables';
.screen {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  align-items: center;
  gap: 1em;

  background-color: $color_background__gray__light;
  width: 100%;
  min-height: 100vh;
  min-height: 100dvh;

  &.withNavbar {
    min-height: calc(100vh - 80px);
    min-height: calc(100dvh - 80px);
  }

  &.light {
    background-color: $color_background__light;
  }

  .header {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    background-color: $color_background__gray__light;
    display: grid;
    grid-template-columns: 3em 1fr 3em;
    grid-template-rows: 3em;
    z-index: 10;
    width: 100%;
    padding: 0 1.25em;
    padding-top: 1.25em;

    &.light {
      background-color: $color_background__light;
    }

    &.diffuse {
      box-shadow: $color_background__gray__light 0px 15px 12px;
    }

    .prefix {
      grid-column: 1;
      grid-row: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .title {
      grid-column: 2;
      grid-row: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Red Hat Display';
      font-style: normal;
      font-weight: 500;
      font-size: 1em;
      color: $color_text__gray;
    }

    .suffix {
      grid-column: 3;
      grid-row: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 1em;
    position: relative;
    height: 100%;
    padding: 0 1.25em;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .footer {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 1.25em;
    padding-bottom: 1.25em;
    background-color: $color_background__gray__light;

    &.light {
      background-color: $color_background__light;
    }
  }
}

@media (min-width: 768px) {
  .screen {
    .header {
      max-width: 400px;
      right: 0;
      left: auto;
    }

    .footer {
      max-width: 400px;
      right: 0;
      left: auto;
    }
  }
}

@media (min-width: 1024px) {
  .screen {
    .header {
      max-width: 490px;
    }

    .footer {
      max-width: 490px;
    }
  }
}
