.spinnerContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  position: fixed;
  bottom: 0;

  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  visibility: hidden;
  opacity: 0;
  transform: translateY(0);

  .spinner {
    width: fit-content;
    height: fit-content;
    background-color: white;
    border: 5px solid white;
    border-radius: 50%;
  }

  &.show {
    visibility: visible;
    opacity: 1;
    transform: translateY(-50px);
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
}
