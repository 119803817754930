@import '../../styles/variables';

.container {
  position: relative;
}

.select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;

  border: 1px solid $color_border;
  border-radius: 1em;

  width: 100%;
  padding: 1em;
  min-height: 2.5em;

  outline: none;
  position: relative;
  z-index: 2;
  transition: all 0.3s linear;

  ::-webkit-scrollbar {
    display: none;
  }

  &.top {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.bottom {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &.middle {
    border-radius: 0;
  }

  .texts {
    display: flex;
    flex-direction: column;
    gap: 0.25em;

    .placeholder {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 500;
      line-height: 14px;
      font-size: 1em;

      color: #000000;
      opacity: 0.6;

      transition: all 0.3s linear;

      &.minimize {
        font-size: 0.625em;
        line-height: 13px;
      }
    }

    span {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 0.8125em;

      color: #000000;
    }
  }
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s linear;
    transform: rotate(90deg) rotateY(0deg);

    &.active {
      transform: rotate(90deg) rotateY(180deg);
    }
  }
}

.ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  background-color: white;

  border: 1px solid $color_border;
  border-radius: 0 0 1em 1em;

  width: 100%;
  max-height: calc(46px * 6);
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;

  position: absolute;
  top: 100%;
  left: 0;

  visibility: hidden;
  z-index: 1;
  overflow: hidden;
  transition: all 0.3s linear;
  transform-origin: top center;
  transform: scaleY(0%);

  &.visible {
    visibility: visible;
    transform: scaleY(100%);
  }

  li {
    display: flex;
    padding: 1em;
    list-style: none;

    border-bottom: 1px solid $color_border;

    width: 100%;

    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 1em;
    line-height: 20px;

    color: #000000;
  }
}
