.background {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1.125em 1.25em;
  gap: 0.5em;
  background-color: #f5f5f5;
  transition: all 0.2s linear;

  &.isRead {
    background-color: #fff;
  }

  .iconContainer {
    display: flex;
    align-items: center;
    width: fit-content;
    height: 1.375em;
  }

  .dataContainer {
    display: flex;
    flex-direction: column;
    gap: 0.25em;
    width: 100%;

    .titleAndDate {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 0.5em;

      .title {
        flex: 4;
        font-family: "Manrope";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;

        &.hidden {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          line-height: 22px;
        }
      }

      .date {
        flex: 1;
        font-family: "Manrope";
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        color: #000000;
        opacity: 0.6;
        width: fit-content;
        text-align: end;
      }
    }
    .bottomContainer {
      width: 80%;

      .text {
        display: block;
        font-family: "Manrope";
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 14px;
        opacity: 0.6;

        &.hidden {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          line-height: 14px;
        }
      }
    }
  }
}
