.progress {
  width: 100%;
  height: 0.5em;

  border-radius: 0.25em;
}

.color {
  height: 100%;
  width: 0%;

  border-radius: 0.25em;
}
