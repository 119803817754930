.list {
  /* Display */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  /* Size */
  width: 100%;
}

.item {
  /* Size */
  width: 100%;
  height: fit-content;

  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: transparent;

  &.item__withDivide {
    border-bottom-color: #e3e3e3;
  }
}
