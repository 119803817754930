.infoWithIcon {
  /* Sizze */
  width: 100%;

  /* Display */
  display: flex;
  align-items: center;
  gap: 1em;
}

.icon {
  width: fit-content;
  filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.2));
}

.infoWithIcon label {
  /* Font */
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  line-height: 1.125em;

  /* Size */
  font-size: 0.8125em;
}
