.container {
  display: flex;
  align-items: center;
  position: relative;

  .sign {
    font-family: "Red Hat Display";
    font-style: normal;
    font-weight: 500;
    line-height: 37px;
    font-size: 1.75em;
    margin-right: 0.5rem;

    color: #000000;
    opacity: 0.4;

    &.isNotEmpty {
      color: #4a865b;
      opacity: 1;
    }

    &.error {
      color: #f42046;
      opacity: 1;
    }
  }

  .valueSection {
    width: fit-content;

    .input {
      outline: none;
      padding: 0;
      border: none;

      font-family: "Red Hat Display";
      font-style: normal;
      font-weight: 500;
      font-size: 2em;
      line-height: 128.3%;

      color: #000000;
    }
  }

  .maxAmount {
    font-family: "Red Hat Display";
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    font-size: 1em;

    color: #000000;
    opacity: 0.5;
    margin-top: 0.25em;
    margin-left: -0.75em;

    &.number {
      padding-top: 0.3em;
      margin-left: 0;
    }
  }

  .errorMessage {
    position: absolute;
    top: 100%;
    left: 0;

    display: flex;
    align-items: center;
    gap: 0.25em;

    span {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 500;
      line-height: 13px;
      font-size: 12px;

      color: #f2002c;
    }
  }
}
