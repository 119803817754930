@import '../../styles/variables.scss';

.segmented {
  width: fit-content;
  height: 2.5em;
  border-radius: 2em;

  background-color: #e7e8f3;

  font-size: 1rem;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  line-height: 19px;

  display: flex;

  // padding: 0.5em;
  box-sizing: border-box;

  position: relative;

  &.block {
    width: 100%;
  }

  .segmented__option {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1em;

    .segmented__option__label {
      font-size: 0.75em;

      text-align: center;
      color: #374bff;

      z-index: 1;

      transition: color 0.2s ease-in-out;

      user-select: none;

      &.selected {
        color: #ffffff;
      }
    }
  }

  .segmented__indicator {
    position: absolute;
    // left: 5px;
    height: 100%;

    // width: 1em;

    background-color: #374bff;

    border-radius: 2em;
    z-index: 0;

    transition: translate 0.2s ease-in-out;
  }
}
