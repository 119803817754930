.button {
  width: 2.25em;
  height: 2.25em;
  border-radius: 0.75em;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(2px);
  position: relative;

  &.size__small {
    width: 1.75em;
    height: 1.75em;
    border-radius: 0.625em;
  }

  &.size__large {
    width: 2.75em;
    height: 2.75em;
    border-radius: 0.75em;
  }

  &.size__xlarge {
    width: 3em;
    height: 3em;
    border-radius: 0.875em;
  }

  .badge {
    position: absolute;
    top: 22%;
    right: 20%;
    width: 0.625em;
    height: 0.625em;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ff0303;
    border-radius: 50%;

    span {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 400;
      font-size: 8px;
      color: white;
      margin-top: -1px;
    }

    &.size__small {
      width: 0.3125em;
      height: 0.3125em;
      top: 27.5%;
      right: 27.5%;

      span {
        visibility: hidden;
      }
    }

    &.size__large {
      width: 0.75em;
      height: 0.75em;
      top: 20%;
      right: 20%;

      span {
        margin-top: -1px;
        margin-left: -1px;
        font-size: 9px;
      }
    }

    &.size__xlarge {
      width: 0.75em;
      height: 0.75em;
      top: 18%;
      right: 23%;

      span {
        margin-top: -2px;
        font-size: 10px;
      }
    }
  }
}
