.statistic {
  /* Display */
  display: flex;
  align-items: center;
  gap: 0.75em;

  /* Size */
  width: 100%;
  padding: 0.75em 0;
}

.prefix {
  /* Display */
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0.75em;

  width: 100%;
}

.center {
  /* Display */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 3;
}

.title {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 1.25em;

  width: 100%;
}

.description {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75em;
  line-height: 1em;

  width: 100%;
}

.sufix {
  width: 100%;
  text-align: end;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  flex: 1;
}

.sufixTitle {
  font-family: 'Red Hat Display';
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 1.25em;
}

.sufixDescription {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75em;
  line-height: 1em;
}

.sufixButton {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
