@import '../../styles/variables.scss';

.card {
  position: relative;
  display: flex;
  font-size: 1rem;
  width: 100%;
  border-radius: 1em;
  overflow: hidden;
  background-color: $color_background__light;

  &.shadow {
    filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.15));
  }

  .accent {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 0.25em;
    height: 100%;
    z-index: 1;

    &.primary {
      background-color: $color-primary;
    }

    &.success {
      background-color: $color-success;
    }

    &.error {
      background-color: $color-error;
    }

    &.yellow {
      background-color: $color-yellow;
    }
  }
  .container {
    display: flex;
    flex-direction: column;
    padding: 1em;
    width: 100%;
    height: 100%;
    gap: 1em;

    .background {
      width: stretch;
      margin: -1em;
      display: flex;
      flex-direction: column;
      position: relative;

      & > .imgBackground {
        position: absolute;
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }

    .header {
      position: relative;
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    .body {
      width: 100%;
      height: fit-content;
      font-family: 'Red Hat Display';
      font-style: normal;
      font-weight: 500;
    }

    .footer {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1em;
      margin: 0 -1em -1em -1em;
      padding: 0.625em 1em;
      height: fit-content;
    }
  }
}
