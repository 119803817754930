.title {
  font-family: 'Red Hat Display';
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  font-size: 1.25em;

  text-align: center;

  color: #000000;

  margin-bottom: 0.5em;
}
