.pinInput {
  /* Display */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Size */
  width: 48px;
  height: 56px;

  /*  Font   */
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  font-size: 22px;
  text-align: center;
  color: #000000;

  /* Border */
  border-width: 1px;
  border-style: solid;
  border-color: #bcbcbc;
  border-radius: 16px;

  &.error {
    /* Border */
    border-color: #f2002c;

    /* Color */
    color: #f2002c;
  }
}

.group {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  &.gap {
    gap: 1em;
  }

  .input {
    display: flex;
    justify-content: center;

    &.distance {
      flex: 1;
    }

    & :focus::placeholder {
      color: transparent;
    }
  }
}

.messageContainer {
  /* Display */
  display: flex;
  align-items: center;
  gap: 0.25em;

  /* Margin */
  margin-top: 0.25em;

  /* Color */
  color: #f2002c;

  span {
    /* Font */
    font-family: 'Manrope';
    font-weight: 500;
    font-size: 0.75em;
  }
}
