.icon {
  font-size: 1em;
}

.icon__size__small {
  font-size: 0.75em;
}

.icon__size__large {
  font-size: 1.25em;
}

.icon__size__xlarge {
  font-size: 1.75em;
}
