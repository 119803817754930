@import '../../styles/variables.scss';

.button {
  /* Size */
  line-height: 1em;
  height: 3em;
  min-width: 3em;
  padding: 0 1em;

  /* Display */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5em;

  /* Colors */
  background: $color_background;
  border-color: transparent;
  color: $color_text;

  /* Border */
  border-style: solid;
  border-width: 1px;
  border-radius: 1em;

  /* Font */
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-align: center;

  &.bordered {
    border-color: $color_border;
  }

  &:disabled {
    color: $color_text__disabled;
    border-color: transparent;
    background: transparent;
  }
}

.button__inline {
  width: fit-content;
}

.button__block {
  width: 100%;
}

.button__variant__primary {
  /* Colors */
  background: $color_primary;
  color: $color_text__light;
}

.button__variant__success {
  /* Colors */
  background: $color_success;
  color: $color_text__light;
}

.button__variant__success__ghost {
  /* Colors */
  background: transparent;
  color: $color_success;
}

.button__variant__error {
  /* Colors */
  background: $color_error;
  color: $color_text__light;
}

.button__variant__error__ghost {
  /* Colors */
  background: transparent;
  color: $color_error;
}

.button__variant__ghost {
  /* Colors */
  background: transparent;
  color: $color_primary;
}

.button__size__xsmall {
  /* Size */
  font-size: 0.5em;
}

.button__size__small {
  /* Size */
  font-size: 0.75em;
}

.button__size__large {
  /* Size */
  font-size: 1.25em;
}

.button__size__xlarge {
  /* Size */
  font-size: 1.5em;
}

.account {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0.875em 1.25em;

  border-radius: 1em;
}

.accountLogo {
  width: fit-content;
}

.accountConnected {
  /* Display */
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5em;

  /* Border */
  border-radius: 1em;

  /* Size */
  padding: 0.5em 0.75em;
  background-color: white;
}

.accountConnected span {
  /* Color */
  color: #000;

  /* Size */
  font-size: 0.875em;

  /* Font */
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  line-height: 0.75em;
}
