@import '../../styles/variables.scss';

.alert {
  /* Size */
  width: 100%;
  height: fit-content;
  padding: 0.75em 1em;

  /* Display */
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1em;

  /* Border */
  border-radius: 1em;

  &.message {
    transition: 0.5s all ease;
  }

  &.alignCenter {
    justify-content: center;
  }

  &.bg_yellow {
    background-color: $color_background__yellow;
    color: $color_text_yellow;
  }

  &.bg_error {
    background-color: $color_error;
    color: white;
  }

  &.bg_success {
    background-color: $color_success;
    color: white;
  }
}

.iconAndText {
  display: flex;
  align-items: center;
  align-self: center;
  gap: 0.5em;

  & > .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;

    & > .title {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 500;
      line-height: 19px;
      font-size: 0.875em;
    }

    & > .subtitle {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 500;
      line-height: 15px;
      font-size: 0.6875em;
      opacity: 0.6;
      white-space: pre-line;
    }
  }
}
